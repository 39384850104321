import React, { useState, useEffect } from "react";

const MouseTracker = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      className="fixed inset-0 z-0"
      style={{
        background: `radial-gradient(circle at ${position.x}px ${position.y}px, rgba(0, 0, 0, 0.1), transparent)`,
        pointerEvents: "none", // This line ensures the div doesn't capture mouse events
      }}
    ></div>
  );
};

export default MouseTracker;
