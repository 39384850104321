import React from "react";

const Footer = () => {
  return (
    <footer className="py-4 text-white bg-gray-900">
      <div className="container mx-auto text-center">
        <p className="text-sm">
          &copy; {new Date().getFullYear()} huntergray.co. All Rights Reserved.
        </p>
        <div className="mt-2">
          <a href="#about" className="mr-4 hover:text-blue-500">
            About
          </a>
          <a href="#projects" className="mr-4 hover:text-blue-500">
            Projects
          </a>
          <a href="#contact" className="hover:text-blue-500">
            Contact
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
