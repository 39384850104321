import React from "react";

const ContactSection = () => {
  return (
    <section className="py-16 bg-gray-900" id="contact">
      <div className="container px-4 mx-auto">
        <h2 className="relative mb-8 text-3xl font-semibold text-center text-white">
          Contact Me
          <div className="absolute w-16 h-2 transform -translate-x-1/2 rounded-full -bottom-2 left-1/2 bg-gradient-to-r from-blue-500 to-purple-500"></div>
        </h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          <div className="p-6 bg-gray-800 rounded-lg shadow-md">
            <h3 className="mb-4 text-xl font-semibold text-gray-300">
              Send a Message
            </h3>
            <form>
              <div className="mb-4">
                <label htmlFor="name" className="block mb-1 text-white">
                  Your Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="John Doe"
                  className="w-full px-4 py-2 text-white bg-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block mb-1 text-white">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="you@example.com"
                  className="w-full px-4 py-2 text-white bg-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block mb-1 text-white">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  placeholder="Your message here..."
                  className="w-full px-4 py-2 text-white bg-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full py-2 text-white transition duration-300 bg-blue-500 rounded-lg hover:bg-blue-600"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
