import React from "react";

const AboutMeSection = () => {
  return (
    <section className="py-16 text-white bg-gray-900" id="about">
      <div className="container mx-auto text-center">
        <div className="mb-8">
          <h2 className="text-4xl font-semibold">About Me</h2>
          <div className="w-16 h-2 mx-auto mt-2 rounded-full bg-gradient-to-r from-blue-500 to-purple-500"></div>
        </div>
        <div className="flex flex-col items-center md:flex-row md:space-x-12">
          <div className="w-48 h-48 overflow-hidden rounded-full shadow-lg md:w-72 md:h-72">
            <img
              src="./memoji.jpeg"
              alt="Profile"
              className="object-cover w-full h-full transition-transform duration-300 transform scale-100 hover:scale-105"
            />
          </div>
          <div className="mt-4 md:w-3/5 mx-[100px]">
            <div className="p-6 bg-gray-800 rounded-md shadow-lg">
              <p className="mb-4 text-gray-300">
                Hello! I'm Hunter Gray, a passionate software developer who
                started coding at the age of 13.
              </p>
              <p className="mb-4 text-gray-300">
                I have a strong foundation in both front-end and back-end
                development and am eager to apply my skills professionally.
              </p>
              <p className="text-gray-300">
                When I'm not coding, I enjoy playing first-person shooter games,
                getting enough rest, and focusing on my studies. Despite being a
                high school junior, I'm dedicated to making a meaningful impact.
              </p>
            </div>
            <div className="pt-4 mt-8 border-t border-gray-700"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMeSection;
