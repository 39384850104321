import React, { useState, useEffect } from "react";
import Header from "./components/Header"; // Import your Header component
import AboutMeSection from "./components/AboutMeSection"; // Import your AboutMeSection component
import SkillsSection from "./components/SkillsSection"; // Import your SkillsSection component
import ProjectsSection from "./components/ProjectsSection"; // Import your ProjectsSection component
import ContactSection from "./components/ContactSection"; // Import your ContactSection component
import Footer from "./components/Footer"; // Import your Footer component
import HeroSection from "./components/HeroSection";
import MouseTracker from "./components/MouseTracking";
import AnimatedLogo from "./components/AnimatedLogo";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <div>
      {isLoading ? (
        <AnimatedLogo/>
      ) : (
        <>
          <Header />
          <HeroSection />
          <AboutMeSection />
          <SkillsSection />
          <ProjectsSection />
          <ContactSection />
          <Footer />
          <MouseTracker></MouseTracker>
        </>
      )}
    </div>
  );
};

export default App;
