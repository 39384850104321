import React from "react";

const HeroSection = () => {
  return (
    <section className="flex flex-col items-center justify-center min-h-screen py-24 bg-gray-900" id="home">
      <div className="container mx-auto text-center">
        <h1 className="w-full text-4xl font-bold text-white backdrop-blur-md">
          Hi there! I'm <span className="text-sky-400">Hunter Gray</span>
        </h1>
        <p className="text-lg text-gray-300">Full Stack Frontend Web/Software Developer</p>
        <div className="flex justify-center mt-8 space-y-4 md:space-y-0 md:space-x-4">
          <a
            href="#contact"
            className="w-full md:w-[140px] h-[50px] flex items-center justify-center text-white bg-blue-500 rounded-lg shadow-md hover:bg-blue-600 transition duration-300"
          >
            Contact Me
          </a>
          <a
            href="#projects"
            className="w-full md:w-[140px] h-[50px] flex items-center justify-center text-blue-500 bg-white rounded-lg shadow-md hover:bg-blue-100 transition duration-300"
          >
            My Projects
          </a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
