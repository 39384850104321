import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCss3,
  faGitAlt,
  faHtml5,
  faJava,
  faJs,
  faPython,
  faReact,
} from "@fortawesome/free-brands-svg-icons";
import SkillCategory from "./SkillCategory";

const SkillsSection = () => {
  return (
    <section className="py-16 text-white bg-gray-900" id="skills">
      <div className="container mx-auto text-center">
        <h2 className="relative mb-8 text-4xl font-semibold">
          My Skills
          <div className="absolute w-16 h-2 transform -translate-x-1/2 rounded-full -bottom-2 left-1/2 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"></div>
        </h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          <SkillCategory
            title="Web Development"
            items={[
              { icon: faJs, text: "JavaScript", url: "https://www.javascript.com/" },
              { icon: faHtml5, text: "HTML", url: "https://html.com/html5/" },
              { icon: faCss3, text: "CSS3", url: "https://www.css3.info/" },
              { icon: faReact, text: "ReactJS", url: "https://react.dev/" },
            ]}
          />
          <SkillCategory
            title="Software Development"
            items={[
              { icon: faJava, text: "Java", url: "https://www.java.com/en/" },
              { icon: faPython, text: "Python", url: "https://www.python.org/" },
              { icon: "/c++.svg", text: "C++", url: "https://cplusplus.com/" },
            ]}
          />
          <SkillCategory
            title="Tools"
            items={[
              { icon: faGitAlt, text: "Git", url: "https://git-scm.com/" },
            ]}
          />
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;
