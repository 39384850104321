import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SkillCategory = ({ title, items }) => {
  return (
    <div className="p-6 text-white bg-gray-900 rounded-lg shadow-lg">
      <h3 className="mb-4 text-2xl font-semibold">{title}</h3>
      <div className="grid grid-cols-1 gap-4">
        {items.map((item, index) => (
          <a
            key={index}
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col items-center p-4 mb-4 transition duration-300 bg-gray-800 rounded-lg hover:bg-gray-700"
          >
            <div className="mb-2">
              {item.icon instanceof Object ? (
                <FontAwesomeIcon icon={item.icon} size="2x" className="text-blue-300" />
              ) : (
                <img src={item.icon} alt={item.text} className="w-6 text-blue-300" />
              )}
            </div>
            <span className="text-lg font-semibold text-center">{item.text}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default SkillCategory;
