import React from "react";

const ProjectsSection = () => {
  return (
    <section className="py-16 bg-gray-900" id="projects">
      <div className="container mx-auto text-white">
        <h2 className="relative flex justify-center mb-8 text-4xl font-semibold">
          Projects
          <div className="absolute w-16 h-2 transform -translate-x-1/2 rounded-full -bottom-2 left-1/2 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"></div>
        </h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {/* Project 1 */}
          <div className="relative overflow-hidden bg-gray-800 rounded-lg shadow-lg">
            <img src="/ify.png" alt="Project 1" className="w-full h-auto" />
            <div className="absolute inset-0 flex items-center justify-center transition duration-300 bg-black opacity-0 bg-opacity-70 hover:opacity-100 hover:bg-opacity-50">
              <div className="p-6 text-center text-white">
                <h3 className="mb-4 text-xl font-semibold">InternshipsForYou</h3>
                <p className="text-sm">
                  This web app showcases the most recent internships, providing
                  key details like company name, role, date, and an application
                  link. It efficiently empowers users to discover and apply for
                  internship opportunities, streamlining their job search
                  process.
                </p>
                <div className="mt-4">
                  <a
                    href="#"
                    className="inline-block px-4 py-2 mr-4 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                  >
                    Live Demo
                  </a>
                  <a
                    href="https://github.com/Droused/Internship-Web-App"
                    className="inline-block px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                  >
                    Source Code
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Project 2 */}
          <div className="relative overflow-hidden bg-gray-800 rounded-lg shadow-lg">
            <img src="/codex.png" alt="Project 2" className="w-full h-auto" />
            <div className="absolute inset-0 flex items-center justify-center transition duration-300 bg-black opacity-0 bg-opacity-70 hover:opacity-100 hover:bg-opacity-50">
              <div className="p-6 text-center text-white">
                <h3 className="mb-4 text-xl font-semibold">Codex</h3>
                <p className="text-sm">
                  Open-source anime streaming service powered by SvelteKit,
                  offering ad-free streaming, elegant UI, and the flexibility
                  to self-host or deploy online. This platform provides user
                  registration, anime streaming, the ability to save anime in
                  custom lists, and automatic progress saving. It uses various
                  self-hosted APIs and databases.
                </p>
                <div className="mt-4">
                  <a
                    href="https://codex.jnagra.com/"
                    className="inline-block px-4 py-2 mr-4 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                  >
                    Live Demo
                  </a>
                  <a
                    href="#"
                    className="inline-block px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                  >
                    Source Code
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;
