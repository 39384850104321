import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className="fixed top-0 left-0 z-10 w-full p-4 bg-gray-900 shadow-md">
      <div className="container flex items-center justify-between mx-auto">
        <div className="flex items-center space-x-2">
          <img src="/logo.png" alt="Logo" className="w-10 h-10" />
          <h1 className="text-xl font-semibold text-white">huntergray</h1>
        </div>

        <nav className="hidden space-x-4 md:flex">
          <a
            href="#about"
            className="text-white hover:text-blue-500"
            onClick={() => scrollToSection("about")}
          >
            About
          </a>
          <a
            href="#projects"
            className="text-white hover:text-blue-500"
            onClick={() => scrollToSection("projects")}
          >
            Projects
          </a>
          <a
            href="#skills"
            className="text-white hover:text-blue-500"
            onClick={() => scrollToSection("skills")}
          >
            Skills
          </a>
          <a
            href="#contact"
            className="text-white hover:text-blue-500"
            onClick={() => scrollToSection("contact")}
          >
            Contact
          </a>
        </nav>

        <div className="md:hidden">
          <button className="text-white" onClick={toggleMenu}>
            {isMenuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      {isMenuOpen && (
        <ul className="absolute left-0 w-full text-gray-900 bg-white border-t border-gray-300 md:hidden top-full">
          <li className="py-2 border-b border-gray-300">
            <a
              href="#about"
              className="block px-4 py-2 hover:text-blue-500"
              onClick={() => scrollToSection("about")}
            >
              About
            </a>
          </li>
          <li className="py-2 border-b border-gray-300">
            <a
              href="#projects"
              className="block px-4 py-2 hover:text-blue-500"
              onClick={() => scrollToSection("projects")}
            >
              Projects
            </a>
          </li>
          <li className="py-2 border-b border-gray-300">
            <a
              href="#skills"
              className="block px-4 py-2 hover:text-blue-500"
              onClick={() => scrollToSection("skills")}
            >
              Skills
            </a>
          </li>
          <li className="py-2">
            <a
              href="#contact"
              className="block px-4 py-2 hover:text-blue-500"
              onClick={() => scrollToSection("contact")}
            >
              Contact
            </a>
          </li>
        </ul>
      )}
    </header>
  );
};

export default Header;
