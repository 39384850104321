import React from "react";

const AnimatedLogo = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-white bg-gradient-to-br from-gray-900 to-gray-900">
      <div className="w-24 h-24 border-4 rounded-full border-gradient animate-pulse">
        <img src="./logo.png" alt="Logo" className="object-cover w-full h-full rounded-full" />
      </div>
      <p className="mt-4 text-xl font-semibold">Loading...</p>
    </div>
  );
};

export default AnimatedLogo;
